<template>
	<div id="app" class="app d-flex flex-column">
		<router-view class="flex" />
		<v-footer />
	</div>
</template>

<script>
import VFooter from '@/components/layout/Footer';

export default {
	components: {
		VFooter,
	},
};
</script>

<style lang="scss">
@import "@/assets/styles/app.scss";
</style>

<template>
	<div :class="$style.footer" class="d-flex flex-column align-center">
		<div :class="$style['social-profiles']" class="d-flex justify-center flex-gap-8">
			<v-button
				v-for="profile in social_profiles"
				:key="profile.name"
				:href="profile.url"
				:icon="profile.icon"
				:alt-text="profile.name"
				class="title-2"
				plain
				secondary
				newTab
			/>
		</div>
		<div :class="$style['footer-text']" class="d-flex flex-column align-center text-center flex-wrap">
			<p class="body-2">
				<router-link :class="$style.link" class="mb-1" to="/contact">Contact</router-link>
				<span class="mx-3">|</span>
				<router-link :class="$style.link" class="mb-1" to="/terms-conditions">Terms &amp; conditions</router-link>
				<span class="mx-3">|</span>
				<router-link :class="$style.link" class="mb-1" to="/privacy-policy">Privacy policy</router-link>
			</p>
			<p :class="$style.copyright" class="body-2">
				© 2020 – get30sec&trade;
			</p>
		</div>
		<cookie-law />
	</div>
</template>

<script>
import VButton from '@/components/Button';
import CookieLaw from 'vue-cookie-law';

export default {
	components: {
		VButton,
		CookieLaw,
	},
	data() {
		return {
			social_profiles: [
				{
					name: 'linkedin',
					icon: ['fab', 'linkedin'],
					url: 'https://www.linkedin.com/company/get30sec/',
				},
				{
					name: 'instagram',
					icon: ['fab', 'instagram'],
					url: 'https://www.instagram.com/get30sec/',
				},
				{
					name: 'facebook',
					icon: ['fab', 'facebook'],
					url: 'https://www.facebook.com/get30sec',
				},
			],
		};
	},
};
</script>

<style lang="scss" module>
.footer {
	padding-bottom: 5.25rem;
}

.copyright {
	text-align: center;
	margin-right: 0.30rem;
	width: 100%;

	@include md {
		width: auto;
		display: inline-block;
	}
}

.spacer {
	&--mobile {
		display: none;

		@include md {
			display: inline-block;
		}
	}
}

.social-profiles {
	margin: 50px 0;

	@include md {
		margin: 100px 0;
	}
}

.link {
	display: inline-block;
}
</style>

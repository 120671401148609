<template>
	<button v-if="!href" :class="buttonClasses" :aria-label="altText" class="d-flex align-center">
		<v-awesome-icon v-if="icon" :icon="icon" :class="iconClasses" />
		<p v-if="text" :class="$style.text" class="primary--text">{{ text }}</p>
	</button>
	<a
		v-else-if="external"
		:href="href"
		:class="buttonClasses"
		:aria-label="altText"
		:target="target"
		class="d-flex align-center"
	>
		<v-awesome-icon v-if="icon" :icon="icon" :class="iconClasses" />
		<p v-if="text" :class="$style.text" class="primary--text">{{ text }}</p>
	</a>
	<router-link
		v-else :to="{ path: href }" :class="buttonClasses"
		:aria-label="altText"
		:target="target"
		class="d-flex align-center"
	>
		<v-awesome-icon v-if="icon" :icon="icon" :class="iconClasses" />
		<p v-if="text" :class="$style.text" class="primary--text">{{ text }}</p>
	</router-link>
</template>

<script>
export default {
	props: {
		text: {
			type: String,
			default: undefined,
		},
		icon: {
			type: [String, Array],
			default: undefined,
		},
		altText: {
			type: String,
			default: 'icon',
		},
		href: {
			type: String,
			default: undefined,
		},
		plain: {
			type: Boolean,
			default: false,
		},
		primary: {
			type: Boolean,
			default: false,
		},
		secondary: {
			type: Boolean,
			default: false,
		},
		blank: {
			type: Boolean,
			default: false,
		},
		newTab: {
			type: Boolean,
			default: false,
		},
	},
	computed: {
		buttonClasses() {
			return [
				this.$style.body,
				{ 'py-4 px-6': !this.plain },
				{ [this.$style['body--plain']]: this.plain },
				{ [this.$style['body--primary']]: this.primary },
				{ [this.$style['body--secondary']]: this.secondary },
			];
		},
		iconClasses() {
			return [
				this.$style.icon,
				{
					'mr-2': this.text,
					[this.$style['icon--text']]: this.text,
				},
			];
		},
		target() {
			return this.newTab ? '_blank' : '_self';
		},
		external() {
			return !!this.href.includes('http');
		},
	},
};
</script>

<style lang="scss" module>
.body {
	cursor: pointer;
	color: $primary;
	border: 1px solid $stone;
	border-radius: 50px;
	background-color: transparent;
	transition: all 0.3s ease-in-out;

	&:hover {
		background-color: $primary;
		text-decoration: none;

		p, .icon {
			color: $white;
		}
	}

	@include md {
		border: 1.5px solid $stone;
		padding: 20px 28px;
	}
}

.body--plain {
	padding: 0;
	border: none;
	border-radius: 0;

	&:hover {
		background-color: transparent;
	}

	.icon {
		transition: all 0.3s ease-in-out;
	}
}

.body--primary {
	background-color: $primary;

	p {
		color: $white;
	}

	&:hover {
		background-color: lighten($primary, 5%);
	}
}

.body--secondary {
	color: $secondary;

	&:hover {
		p, .icon {
			color: $primary;
		}
	}
}

.icon {
	&--text {
		font-size: 1.5rem;

		@include md {
			font-size: 2rem;
		}
	}
}

.text {
	font-size: 1.125rem;
	line-height: 25px;
	font-weight: bold;

	@include md {
		font-size: 1.4rem;
		line-height: 25px;
	}
}
</style>

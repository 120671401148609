import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '@/views/Home';
import PolicyPage from '@/views/PolicyPage';
import Contact from '@/views/Contact';

import TermsAndConditionsData from '@/assets/json/terms-conditions.json';
import PrivacyData from '@/assets/json/privacy.json';

Vue.use(VueRouter);

const routes = [
	{
		path: '/',
		name: 'home',
		component: Home,
	},
	{
		path: '/terms-conditions/',
		name: 'terms-conditions',
		component: PolicyPage,
		meta: {
			title: 'General Terms & Conditions - get30sec',
			items: TermsAndConditionsData,
		},
	},
	{
		path: '/privacy-policy',
		name: 'privacy-policy',
		component: PolicyPage,
		meta: {
			items: PrivacyData,
		},
	},
	{
		path: '/contact',
		name: 'contact',
		component: Contact,
	},
];

const router = new VueRouter({
	mode: 'history',
	routes,
});

router.beforeEach((to, from, next) => {
	window.scrollTo(0, 0);
	next();
});

export default router;

<template>
	<div ref="anchor" :class="$style.anchor">
		<div ref="navbar" :class="navbarClasses">
			<slot :in-navbar="showNavbar" />
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			showNavbar: false,
			lastScrollPosition: 0,
			height: 0,
		};
	},
	async mounted() {
		window.addEventListener('scroll', this.onScroll);
		await this.$nextTick();
		this.height = this.$refs.navbar.clientHeight;
	},
	beforeDestroy() {
		window.removeEventListener('scroll', this.onScroll);
	},
	computed: {
		navbarClasses() {
			return [this.$style.navbar, { [this.$style['navbar--active']]: this.showNavbar }];
		},
	},
	methods: {
		onScroll() {
			const currentScrollPosition = window.pageYOffset || document.documentElement.scrollTop;
			this.showNavbar = currentScrollPosition > this.$refs.anchor.offsetTop;
		},
	},
};
</script>

<style lang="scss" module>
.anchor {
	width: 100vw;
}

.navbar {
	transition: box-shadow 0.3s ease-in;
	padding: 0;
}

.navbar--active {
	z-index: 2;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	background-color: $pearl;
	margin: 0;
	box-sizing: border-box;
	box-shadow: 0 15px 15px 0 rgba(0, 0, 0, 0.10);
}
</style>

<template>
	<div class="d-flex flex-column align-center mt--lg-12 px-3">
		<content-into-navbar :class="[$style.navbar]">
			<div :class="[$style['navbar-home']]" class="d-flex justify-end pa-3 mx-auto">
				<v-container class="py-0">
					<v-row justify="center" dense class="py-0">
						<v-col cols="12" md="8" lg="6" xl="4" class="d-flex justify-end py-0">
							<v-button
								:class="$style.button"
								icon="home" href="/" class="body-1"
							/>
						</v-col>
					</v-row>
				</v-container>
			</div>
		</content-into-navbar>

		<transition name="fade" mode="out-in">
			<v-container v-if="isSent">
				<v-row justify="center" dense>
					<v-col cols="12" md="8" lg="6" xl="4" class="text-center">
						<h2 class="mt-2 text-center mb-10">{{ sentTitle }}</h2>
						<p>{{ sentText }}</p>
					</v-col>
				</v-row>
			</v-container>

			<v-form
				v-if="!isSent"
				v-model="formValid"
				ref="form"
				:class="$style.form"
				class="flex d-flex flex-column v-application"
				@submit.prevent="submit"
			>
				<v-container>
					<h2 class="mt-2 text-center mb-10">{{ title }}</h2>
					<v-row justify="center" dense>
						<v-col cols="12" md="8" lg="6" xl="4">
							<v-text-field
								v-model="form.name.value"
								:rules="nameRules"
								:class="$style.input"
								:placeholder="form.name.placeholder"
								class="v-application g30s-input"
								height="50"
								background-color="white"
								rounded
							>
								<template v-slot:message="{ message }">
									<div class="ml-6">{{ message }}</div>
								</template>
							</v-text-field>
							<v-text-field
								v-model="form.email.value"
								:rules="emailRules"
								:class="$style.input"
								:placeholder="form.email.placeholder"
								class="v-application g30s-input"
								height="50"
								background-color="white"
								rounded
							>
								<template v-slot:message="{ message }">
									<div class="ml-6">{{ message }}</div>
								</template>
							</v-text-field>
							<v-textarea
								v-model="form.message.value"
								:rules="messageRules"
								:class="$style.input"
								:placeholder="form.message.placeholder"
								class="v-application g30s-input"
								background-color="white"
								rounded
							>
								<template v-slot:message="{ message }">
									<div class="ml-6">{{ message }}</div>
								</template>
							</v-textarea>
							<p class="primary--text body-1">{{ fileText }}</p>
							<v-file-input
								v-model="form.file.value"
								:rules="form.file.rules"
								:class="$style.input"
								:placeholder="form.file.placeholder"
								accept=".png, .jpg, .mp4, .pdf"
								class="v-application g30s-input"
								height="50"
								background-color="white"
								rounded show-size
							>
								<template v-slot:prepend>
									<v-icon class="mt-2">$file</v-icon>
								</template>
							</v-file-input>
							<vue-recaptcha
								ref="recaptcha"
								:loadRecaptchaScript="true"
								@verify="onCaptchaVerified"
								@expired="onCaptchaExpired"
								size="invisible"
								sitekey="6Le_wC0aAAAAAMhk8H4ZWEudROexxN3MUEGc6BRM">
							</vue-recaptcha>
						</v-col>
					</v-row>
					<v-row justify="center">
						<v-col cols="12" md="8" lg="6" xl="4" class="d-flex justify-center">
							<v-btn
								:class="$style.send" :loading="isSending" height="55"
								color="primary" rounded
								type="submit"
							>
								Send
							</v-btn>
						</v-col>
					</v-row>
					<v-row justify="center">
						<v-col cols="12" md="8" lg="6" xl="4" class="d-flex justify-center">
							<p v-if="error" class="error--text body-1 ma-0">{{ error }}</p>
						</v-col>
					</v-row>
				</v-container>
			</v-form>
		</transition>
	</div>
</template>

<script>
import VButton from '@/components/Button';
import ContentIntoNavbar from '@/components/ContentIntoNavbar';
import VueRecaptcha from 'vue-recaptcha';

const required = v => (v || '').length > 0 || 'This field is required';
const email = v => /.+@.+\..+/.test(v) || 'E-mail must be valid';
const fileSize = size => v => !v || v.size < size * 1000000 || `Avatar size should be less than ${size} MB!`;
const acceptedFileTypes = ['jpg', 'png', 'mp4', 'pdf'];
const fileType = types => v => !v || types.includes(v.name.split('.')[1]) || 'Unsupported file type';

const status = {
	IDLE: 'IDLE',
	SUBMITTING: 'SUBMITTING',
	SUBMITTED: 'SUBMITTED',
	FAILED: 'FAILED',
};

export default {
	components: {
		VButton,
		ContentIntoNavbar,
		VueRecaptcha,
	},
	data() {
		return {
			status: status.IDLE,
			formValid: false,
			title: 'Let\'s get in touch!',
			sentTitle: 'Thanks for getting in touch!',
			sentText: 'Your message has been successfully sent. We will review your message as soon as possible.',
			fileText: 'Or just share your video with us made with get30sec',
			form: {
				name: {
					value: '',
					placeholder: 'Name',
					rules: [],
				},
				email: {
					value: '',
					placeholder: 'Email',
					rules: [email],
				},
				message: {
					value: '',
					placeholder: 'Message',
					rules: [],
				},
				file: {
					value: undefined,
					placeholder: 'File input',
					rules: [fileSize(10), fileType(acceptedFileTypes)],
				},
			},
			error: '',
		};
	},
	computed: {
		isSent() {
			return this.status === status.SUBMITTED;
		},
		isSending() {
			return this.status === status.SUBMITTING;
		},
		hasFailed() {
			return this.status === status.Failed;
		},
		nameRules() {
			if (this.form.file.value) {
				return [];
			}
			return [...this.form.name.rules, required];
		},
		emailRules() {
			if (this.form.file.value) {
				return [];
			}
			return [...this.form.email.rules, required];
		},
		messageRules() {
			if (this.form.file.value) {
				return [];
			}
			return [...this.form.message.rules, required];
		},
	},
	methods: {
		onCaptchaVerified(recaptchaToken) {
			this.status = status.SUBMITTING;
			this.$refs.recaptcha.reset();
			this.sendForm(recaptchaToken);
		},
		onCaptchaExpired: () => {
			this.$refs.recaptcha.reset();
		},
		submit() {
			this.error = undefined;
			if (!this.$refs.form.validate() || this.isSending) {
				return;
			}

			this.$refs.recaptcha.execute();
		},
		async sendForm(recaptchaToken) {
			const form = new FormData();
			form.append('recaptcha_token', recaptchaToken);

			if (this.form.name.value) {
				form.append('name', this.form.name.value);
				form.append('email', this.form.email.value);
				form.append('message', this.form.message.value);
			}

			if (this.form.file.value) {
				form.append('attached_file', this.form.file.value);
			}

			await this.$http.post('/api/contact/', form, {
				headers: {
					'Content-Type': 'application/x-www-form-urlencoded',
				},
			}).then(() => {
				this.status = status.SUBMITTED;
			}, (response) => {
				console.log({ ...response });
				this.error = 'Something went wrong, try again later';
				this.status = status.FAILED;
			});
		},
	},
};
</script>

<style lang="scss" module>
.form {
	width: 100%;

	@include lg {
		justify-content: center;
	}
}

.input {
	textarea {
		padding: 12px 0 !important;
	}

	button:first-child {
		font-size: 1.75rem;
	}

}

.send {
	width: 100%;

	@include md {
		max-width: 175px;
	}
}

.navbar-home {
	max-width: 100%;
}

.navbar {
	height: 70px;
}
</style>

<style lang="scss">
.g30s-input {
	.v-input__prepend-outer {
		font-size: 1.75rem;
		margin-top: 15px;
	}
	.v-input__append-inner {
		align-self: center !important;
		margin-top: 0 !important;
	}
	.v-input__control {
		margin-left: 10px;
	}
}
</style>

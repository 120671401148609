<template>
	<div class="d-flex flex-column align-center">
		<section :class="$style.heading" class="d-flex flex-column align-center justify-space-between">
			<img src="@/assets/img/logo.svg" alt="logo" :class="$style.logo" loading="lazy">
			<h1 class="text-center white--text">
				<template v-for="part in title">
					{{ part }} <br :key="part">
				</template>
			</h1>
		</section>
		<spacer :z-index="1" :class="$style['spacer-1']" />

		<section :class="$style.section" class="d-flex flex-column flex-gap-8">
			<p :class="$style['section-margin']" class="subtitle-1 text-center">
				{{ subTitle }}
			</p>

			<div>
				<p class="subtitle-1 text-center primary--text">Download get30sec for free on iOS or Android</p>
				<content-into-navbar :class="$style.navbar">
					<div :class="[$style['app-buttons'], $style['section-margin']]" class="d-flex justify-center">
						<v-button
							:text="appleButton.text"
							:icon="appleButton.icon"
							:href="appleButton.href"
							:class="[$style.button, $style['button--apple']]"
							newTab
						/>
						<v-button
							:text="androidButton.text"
							:icon="androidButton.icon"
							:href="androidButton.href"
							:class="[$style.button]"
							newTab
						/>
					</div>
				</content-into-navbar>
			</div>

			<div :class="$style.steps" class="d-flex flex-column flex-gap-8">
				<step-block
					v-for="(step, index) in stepBlocks"
					:key="index"
					:img="step.img"
					:img-mobile="step.imgMobile"
					:img-left="step.imgLeft"
					:img-alt="step.imgAlt"
					:title="step.title"
					:text="step.text"
					:full-width="step.fullWidth"
					:class="{ 'text-center': step.textCenter }"
				/>
			</div>

			<spacer :class="$style['spacer-2']" />

			<p class="subtitle-1 primary--text text-center content-margin">
				This was a 30 second read :-)
			</p>
		</section>
	</div>
</template>

<script>
import VButton from '@/components/Button';
import Spacer from '@/components/Spacer';
import StepBlock from '@/components/StepBlock';
import ContentIntoNavbar from '@/components/ContentIntoNavbar';

export default {
	components: {
		VButton,
		Spacer,
		StepBlock,
		ContentIntoNavbar,
	},
	data() {
		return {
			title: ['30 seconds is', 'all you got'],
			subTitle: `
				In this fast-paced world the time for your first impression is very limited.
				With get30sec you can now easily create your 30 second power pitch.
			`,
			appleButton: {
				icon: ['fab', 'apple'],
				text: 'iOS',
				href: 'https://apps.apple.com/us/app/get30sec/id1519431305',
			},
			androidButton: {
				icon: ['fab', 'google-play'],
				text: 'Android',
				href: 'https://play.google.com/store/apps/details?id=com.getthirtyseconds',
			},
			stepBlocks: [
				{
					img: 'step1.jpg',
					imgMobile: 'step1-mobile.jpg',
					title: '1. Create your pitch deck',
					text: `
						Select one of the predefined subjects to pitch your idea,
						your product, your company or yourself.
						Or make up your own pitch deck. It’s that easy.
					`,
				}, {
					img: 'step2.jpg',
					imgMobile: 'step2-mobile.jpg',
					imgLeft: true,
					title: '2. Create and style your video',
					text: `
						Cover the subjects in your pitch deck within 30 seconds.
						Add some filters, text or images to make it all yours.
						The videos get automatically combined in one power pitch.
					`,
				}, {
					img: 'step3.jpg',
					title: '3. Share your pitch, blow them away!',
					text: '',
					textCenter: true,
					fullWidth: true,
				},
			],
		};
	},
};
</script>

<style lang="scss" module>
.logo {
	@include lg {
		width: 250px;
	}
}

.spacer-1 {
	@include md {
		height: 150px;
		margin-top: -75px;
	}
}

.spacer-2 {
	height: 150px;
	margin-top: -75px;
}

.heading {
	transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
	width: 100%;
	min-height: 0px;
	background-image: url('~@/assets/img/get30sec-background.jpg');
	background-position: center center;
	background-repeat: no-repeat;
	background-size: cover;
	position: relative;
	padding: 20px 0 70px;
	min-height: 75vh;

	@include md {
		min-height: 87vh;
		padding: 30px 0 120px;
		background-position: center center;
	}

	@include lg {
		background-position: top;
		padding-bottom: 125px;
	}
}

.section {
	width: 100%;
	margin: 16px 0 0;

	@include md {
		align-items: center;
		gap: 32px;
		margin: 16px 20px 0;
	}

	@include lg {
		max-width: 970px !important;
	}

	.steps {
		margin-top: 32px;

		@include md {
			align-items: center;
			gap: 64px;
			margin-bottom: 96px;
		}
	}

	.section-margin {
		// change how this margin is handled
		margin: 0 20px;

		@include sm {
			margin: 0 90px;
		}
	}

	.app-buttons {
		max-width: 100%;
		gap: 16px;
		padding: 16px 20px;
		box-sizing: border-box;

		.button {
			height: 50px;
			// min-width: 175px;

			@include md {
				height: 75px;
			}

			&--apple {
				svg {
					margin-top: -5px;
						font-size: 1.75rem;

					@include md {
						font-size: 2.5rem;
					}
				}
			}
		}

		@include md {
			padding: 20px;
		}
	}

	.navbar {
		height: 90px;

		@include md {
			height: 120px;
		}
	}
}
</style>

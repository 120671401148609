<template>
	<div class="d-flex justify-center px-3">
		<div :class="$style.section" class="d-flex flex-column align-center">
			<content-into-navbar :class="[$style.navbar]">
				<div :class="[$style['navbar-home']]" class="d-flex justify-end pa-3 mx-auto">

					<v-button
						:class="$style.button"
						icon="home" href="/" class="body-1"
					/>
				</div>
			</content-into-navbar>

			<h3 v-if="title" class="self-align-start mb-5">{{ title }}</h3>

			<div :class="$style.content" class="d-flex flex-column flex-gap-8">
				<div
					v-for="(item) in markdownItems"
					:key="item"
					:class="$style.markdown"
					class="white pa-4 secondary--text"
					v-html="item"
				/>
			</div>
		</div>
	</div>
</template>

<script>
import marked from 'marked';
import DOMPurify from 'dompurify';
import VButton from '@/components/Button';
import ContentIntoNavbar from '@/components/ContentIntoNavbar';

export default {
	components: {
		VButton,
		ContentIntoNavbar,
	},
	data() {
		return {
			title: this.$router.currentRoute.meta.title,
			items: this.$router.currentRoute.meta.items,
		};
	},
	computed: {
		markdownItems() {
			return this.items.map(item => DOMPurify.sanitize(marked(item)));
		},
	},
	beforeRouteEnter(to, from, next) {
		next((vm) => {
			const comp = vm;
			comp.title = to.meta.title;
			comp.items = to.meta.items;
		});
	},
	methods: {
		formatMarkdown(text) {
			return text;
		},
	},
};
</script>

<style lang="scss" module>
.section, .content, .navbar-home {
	max-width: 100%;

	@include lg {
		max-width: 970px !important;
	}
}

.markdown {
	p {
		font-size: 1rem;
		line-height: 24px;
		margin-bottom: 12px;
	}

	a {
		color: $purple;
	}
}

.button {
	padding: 12px 20px;
}

.navbar {
	height: 78px;

	@include md {
		height: 84px;
	}
}
</style>

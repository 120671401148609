<template>
	<div :class="$style.spacer" class="d-flex justify-center">
		<span :class="$style.line" :style="zIndexStyle" />
	</div>
</template>

<script>
export default {
	props: {
		zIndex: {
			type: [Number, String],
			default: 0,
		},
	},
	computed: {
		zIndexStyle() {
			const styles = {};
			if (this.zIndex) {
				styles['z-index'] = this.zIndex;
			}
			return styles;
		},
	},
};
</script>

<style lang="scss" module>
.spacer {
	width: 100%;
	height: 100px;
	margin-top: -50px;
}

.line {
	height: 100%;
	width: 2px;
	background-color: $white;
}
</style>

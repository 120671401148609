import Vue from 'vue';
import App from './App';
import router from './router';
import store from './store';
import './plugins/font-awesome';
import vuetify from './plugins/vuetify';
import './plugins/vue-resource';

Vue.config.productionTip = false;

new Vue({
	router,
	store,
	vuetify,
	render: h => h(App),
}).$mount('#app');

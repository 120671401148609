<template>
	<div :class="stepBlockClasses" class="d-flex flex-column">
		<div :class="imgWrapperClasses">
			<img :src="require(`../assets/img/${img}`)" :alt="imgAlt" :class="imgClasses" loading="lazy">
		</div>
		<img :src="require(`../assets/img/${mobileImgSrc}`)" :alt="imgAlt" :class="$style['img-mobile']" loading="lazy">
		<div :class="contentClasses" class="d-flex flex-column flex-gap-8">
			<h2>{{ title }}</h2>
			<p v-if="text" class="body-1">{{ text }}</p>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		img: {
			type: String,
			required: true,
		},
		imgMobile: {
			type: String,
			default: undefined,
		},
		imgAlt: {
			type: String,
			default: 'image',
		},
		imgLeft: {
			type: Boolean,
			default: false,
		},
		title: {
			type: String,
			required: true,
		},
		text: {
			type: String,
			default: undefined,
		},
		fullWidth: {
			type: Boolean,
			default: false,
		},
	},
	computed: {
		mobileImgSrc() {
			return this.imgMobile || this.img;
		},
		stepBlockClasses() {
			return [
				this.$style['step-block'],
				{ [this.$style['step-block--full-width']]: this.fullWidth },
				{ [this.$style['step-block--img-left']]: this.imgLeft },
			];
		},
		imgWrapperClasses() {
			return [
				this.$style['img-wrapper'],
				{ [this.$style['img-wrapper--full-width']]: this.fullWidth },
			];
		},
		imgClasses() {
			return [
				this.$style.img,
				{ [this.$style['img--left']]: this.imgLeft },
			];
		},
		contentClasses() {
			return [
				this.$style.content,
				{ [this.$style['content--full-width']]: this.fullWidth },
				{ [this.$style['content--img-left']]: this.imgLeft },
			];
		},
	},
};
</script>

<style lang="scss" module>
.step-block {
	z-index: 0;
	margin: 0 20px;

	&--full-width {
		margin: 0;

		.img-mobile {
			height: 400px;
		}
	}

	@include md {
		min-height: 450px;
		flex-direction: row-reverse;
		margin: 56px 110px 0 20px;

		&--img-left {
			flex-direction: row;
			margin: 56px 20px 0 110px;
		}

		&--full-width {
			flex-direction: column;
			margin: 0;
		}
	}

	@include lg {
		margin-right: 128px;

		&--img-left {
			flex-direction: row;
			margin-left: 128px;
			margin-right: 0;
		}

		&--full-width {
			margin: 0;
			width: 100%;
			flex-direction: column;
		}
	}
}

.img-mobile {
	width: 100%;
	object-fit: cover;
	display: block;

	@include md {
		display: none;
	}
}

.img-wrapper {
	position: relative;
	min-height: 350px;
	display: none;

	@include md {
		display: block;
	}

	.img {
		width: 250px;
		object-fit: cover;
		position: absolute;
		top: -56px;
		left: -160px;

		@include lg {
			left: -122px;
		}

		&--left {
			top: -56px;
			left: -90px;

			@include lg {
				left: -129px;
			}
		}
	}

	&--full-width {
		margin: 0;

		.img {
			width: 100%;
			position: relative;
			top: 0;
			left: 0;
		}
	}
}

.content {
	background-color: $white;
	padding: 25px 33px 45px;

	&--full-width {
		padding: 25px;
		margin: -50px 33px -25px;
		z-index: 1;
	}

	@include md {
		padding: 50px 210px 50px 60px;

		&--img-left {
			padding: 50px 60px 50px 210px;
		}

		&--full-width {
			min-height: 0;
			padding: 50px 60px;
			margin: -50px 90px -100px;
		}
	}

	@include lg {
		&--full-width {
			min-height: 0;
			padding: 50px 60px;
			margin: -60px 90px -100px;
		}
	}
}
</style>
